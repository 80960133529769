
import { IonBackButton, IonToolbar, IonButtons } from '@ionic/vue'

export default {
  name: 'Profile',
  components: {
    IonButtons,
    IonBackButton,
    IonToolbar,
  },
  props: {
    text: String,
    white: Boolean,
  },
}
