
import { IonCol, IonRow, IonIcon } from '@ionic/vue'

export default {
  components: {
    IonCol,
    IonRow,
    IonIcon,
  },
  props: {
    headingText: String,
    linkText: String,
    iconName: String,
  },
}
