
import AppHeader from '@/components/header/AppHeader.vue'
import UserNews from '@/components/homepage/UserNews.vue'
import TabsBox from '@/components/homepage/TabsBox.vue'
import ThreeTabsNav from '@/components/homepage/ThreeTabsNav.vue'
import DailyFeed from '@/components/homepage/FeedContent.vue'

import { IonPage, IonContent } from '@ionic/vue'

export default {
  components: {
    IonPage,
    IonContent,
    AppHeader,
    UserNews,
    TabsBox,
    ThreeTabsNav,
    DailyFeed,
  },
}
