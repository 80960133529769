<template>
  <p class="post-info">
    <span>{{ author }}</span><br />
    <span class="text-color-medium-shade small-text">12 minutes ago</span>
  </p>
</template>

<script lang="ts">
export default {
  props: {
    author: String,
    posted: String,
  },
}
</script>

<style scoped>
.post-info {
  text-align: right;
  margin: 0;
}
</style>
