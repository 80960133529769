<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <back-link v-if="backLinkHeader" :white="true" />
        <ion-button
          v-if="!backLinkHeader"
          slot="icon-only"
          router-link="/options"
          router-direction="forward"
        >
          <ion-icon name="menu-outline" size="large" />
        </ion-button>
        <ion-button
          v-if="!backLinkHeader"
          id="search-icon"
          slot="icon-only"
        >
          <ion-icon name="search-outline" size="large" />
        </ion-button>
      </ion-buttons>

      <router-link v-if="!backLinkHeader" to="/tabs/home">
        <spinorama-logo size="small" />
      </router-link>
      <h2 v-if="backLinkHeader">
        {{ title }}
      </h2>

      <ion-buttons id="right-header-slot" slot="end">
        <ion-button
          v-if="!backLinkHeader"
          id="show-profile"
          router-link="/profile"
          router-direction="forward"
        >
          <ion-avatar slot="icon-only">
            <img
              src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
              alt="avatar"
            />
          </ion-avatar>
        </ion-button>
        <ion-badge v-if="!backLinkHeader" id="user-num">
          {{ user.score }}
        </ion-badge>
        <ion-button
          v-if="backLinkHeader"
          slot="icon-only"
          router-link="/options"
          router-direction="forward"
        >
          <ion-icon name="menu-outline" size="large" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts">
import BackLink from '@/components/settings/BackLink.vue'
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonAvatar,
  IonButton,
  IonBadge,
  IonIcon,
} from '@ionic/vue'
import SpinoramaLogo from '@/components/header/SpinoramaLogo.vue'
import { useStore } from '@/store'

export default {
  name: 'AppHeader',
  components: {
    IonButtons,
    IonButton,
    IonHeader,
    IonToolbar,
    IonAvatar,
    IonBadge,
    IonIcon,
    SpinoramaLogo,
    BackLink,
  },
  props: {
    title: String,
    backLinkHeader: Boolean, // set to 'true' for header with backlink and page title, header with logo is default
  },
  setup() {
    const { state } = useStore()

    return {
      user: state.user,
    }
  },
}
</script>

<style scoped>
h2 {
  text-align: center;
}

ion-header {
  padding: 6px 15px;
  background-color: var(--ion-color-primary);
}

.header-md::after {
  background: none;
}

ion-toolbar {
  --background: none;
}

#search-icon {
  margin-left: 15px;
}

h1 {
  padding-bottom: 10px;
}

#right-header-slot {
  margin-left: 5px;
}

ion-avatar,
#show-profile {
  width: 45px;
  height: 45px;
}

ion-button::part(native) {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

#user-num {
  bottom: 32px;
  left: 28px;
  font-size: 8px;
  padding: 5px;
}
</style>
