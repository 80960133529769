<template>
  <ion-avatar>
    <ion-badge v-if="friends">
      <ion-icon :icon="peopleSharp" />
    </ion-badge>
    <img class="avatar" :src="authorAvatarSrc" />
  </ion-avatar>
</template>

<script lang="ts">
import { IonAvatar, IonBadge, IonIcon } from '@ionic/vue'

import { peopleSharp } from 'ionicons/icons'

export default {
  components: {
    IonAvatar,
    IonBadge,
    IonIcon,
  },
  props: {
    authorAvatarSrc: String,
    friends: Boolean,
  },
  setup() {
    return {
      peopleSharp,
    }
  },
}
</script>

<style scoped>
.avatar {
  max-height: 50px;
  max-width: 50px;
}

ion-badge {
  top: -3px;
  left: -3px;
  width: 30px;
  height: 30px;
}

ion-icon {
  font-size: 20px;
}
</style>
