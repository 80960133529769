import { State } from './index'
import { User } from '@/api/api'

const mutations = {
  saveToken(state: State, { token, user }: { token: string, user: User }) {
    state.authToken = token
    state.user = user
  },
}

export default {
  ...mutations,
}
