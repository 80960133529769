<template>
  <ion-grid>
    <ion-row>
      <ion-col v-for="(item, index) in notifications" :key="index">
        <div class="white-container box">
          <router-link :to="item[3]">
            <ion-badge>{{ item[2] }}</ion-badge>
            <ion-icon :icon="item[1]" />
            <ion-label>
              {{ $t(item[0]) }}
            </ion-label>
          </router-link>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script lang="ts">
import { IonGrid, IonRow, IonCol, IonBadge, IonIcon } from '@ionic/vue'

import {
  peopleSharp,
  trophySharp,
  rocketSharp,
  readerSharp,
  playCircleSharp,
  bulbSharp,
} from 'ionicons/icons'

export default {
  name: 'Notifications',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonBadge,
    IonIcon,
  },
  setup() {
    return {
      peopleSharp,
      trophySharp,
      rocketSharp,
      readerSharp,
      playCircleSharp,
      bulbSharp,
    }
  },
  data() {
    return {
      notifications: [
        ['Friends', peopleSharp, 12, '/tabs/friends'],
        ['Challenges', trophySharp, 2, '/tabs/challenges'],
        ['Tasks', readerSharp, 6, '/tabs/home/todos'],
        ['Programs', rocketSharp, 1, '/tabs/programs'],
        ['Training videos', playCircleSharp, 3, '/tabs/training-videos'],
        ['Hockey tips', bulbSharp, 56, '/tabs/tips'],
      ],
    }
  },
}
</script>

<style scoped>
ion-grid {
  padding: 0;
  margin: 20px auto auto auto;
}

ion-badge {
  top: -5px;
  right: -5px;
  font-size: 12px;
  padding: 10px;
  width: 35px;
  height: 35px;
}

ion-icon {
  color: var(--ion-color-primary);
  font-size: 50px !important;
  margin-top: 18px;
}

ion-label {
  display: block;
  font-size: 10px;
}

.white-container {
  position: relative;
  padding: 5px;
  margin: 5px auto;
  text-align: center;
  color: var(--ion-color-primary);
  width: 100px;
  height: 100px;
  border-radius: 25%;
}
.white-container:hover {
  background-color: var(--ion-color-medium);
}

a {
  display: block;
}
</style>
