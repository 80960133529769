
import { IonRow, IonCol, IonIcon } from '@ionic/vue'

import { thumbsUpOutline, shareSocialOutline, chatbubbleEllipsesOutline } from 'ionicons/icons'

export default {
  components: {
    IonRow,
    IonCol,
    IonIcon,
  },
  props: {
    social: Object,
  },
  setup() {
    return {
      thumbsUpOutline,
      shareSocialOutline,
      chatbubbleEllipsesOutline,
    }
  },
}
