import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { IonicVue } from '@ionic/vue'

import * as Sentry from '@sentry/browser'

import veProgress from 'vue-ellipse-progress'

import i18n from './i18n'

import { store, key } from '@/store/index'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/global.css'

import { defineCustomElements } from '@ionic/pwa-elements/loader'

const prod = process.env.NODE_ENV === 'production'

if (prod) {
  Sentry.init({
    dsn: 'https://74fc776e67924f978f82eb519d0043bc@o71591.ingest.sentry.io/5600090',
  })
}

defineCustomElements(window)

const app = createApp(App)
  .use(IonicVue, { mode: 'md' })
  .use(router)
  .use(store, key)
  .use(i18n)
  .use(veProgress)

router.isReady().then(() => {
  app.mount('#app')
})
