<template>
  <ion-grid class="main-content">
    <div id="news">
      <ion-row id="player-column">
        <ion-col>
          <ion-row>
            <h2 class="greeting">
              {{ $t('Hello') }}<br />
              <span>{{ user.name }}</span>!
            </h2>
          </ion-row>
          <ion-row>
            <p>{{ $t('Since the last time you were here there is something NEW here:') }}</p>
          </ion-row>
        </ion-col>
        <img src="@/assets/img/hockeyplayer.png" alt="hockey player image" />
      </ion-row>
      <ion-row>
        <notifications-box />
      </ion-row>
    </div>
  </ion-grid>
</template>

<script lang="ts">
import NotificationsBox from '@/components/homepage/NotificationsBox.vue'

import { IonGrid, IonRow, IonCol } from '@ionic/vue'

import { useStore } from '@/store'

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    NotificationsBox,
  },
  props: {
    showNews: Boolean,
  },
  setup() {
    const { state } = useStore()

    return {
      user: state.user,
    }
  },
}
</script>

<style scoped>
ion-grid {
  max-width: var(--home-content-max-width);
  padding-bottom: 0;
}

#news {
  margin-top: 20px;
}

.greeting {
  font-size: var(--logo-font-size-bigger);
  font-weight: 400;
}

p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

img {
  max-height: 200px;
  height: 100%;
  margin-left: auto;
}

#player-column {
  display: flex;
  margin: auto;
  justify-content: space-around;
}
</style>
