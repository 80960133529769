export interface User {
  id: string
  name: string
  surname: string
  role: string
  score: number
  completion: number
  image: string | null
  friends: number
  followers: number
  about: string | null
  addresses: [Address]
  clubs: [
    {
      name: string
      image: string | null
      role: string
    }
  ]
}

export interface Address {
  street: string | null
  house: string | null
  city: string | null
  zip: string | null
}

export function logIn(email: string, password: string) {
  console.log('User API call', email, password)

  return new Promise(res => {
    setTimeout(() => {
      res({
        token: 'asdasdkajfhakg',
        user: {
          id: '1',
          name: 'John',
          surname: 'Doe',
          role: 'player',
          score: 87,
          completion: 60,
          friends: 24,
          followers: 122,
          clubs: [
            {
              name: 'VUT Brno',
            },
          ],
          addresses: [{ city: 'San Francisco, CA' }],
          about:
            'Hi! My name is John, I’m a player from San Francisco, CA. Contact me at john@mail.com',
        },
      })
    }, 3000)
  })
}

export interface ListVideo {
  id: number
  title: string
  subtitle: string
  image: string
}

export interface DetailVideo {
  videoUrl: string
  metadata: []
}

export function getVideos(filter: Record<string, unknown> = {}) {
  console.log('Videos API call', filter)

  return new Promise(res => {
    setTimeout(() => {
      res([
        {
          id: 2,
          title: 'My video',
          subtitle: '19. 2. 2020',
          image: 'https://picsum.photos/id/10/600/500',
        },
        {
          id: 3,
          title: 'My video 2',
          subtitle: '18. 1. 2020',
          image: 'https://picsum.photos/id/1006/600/500',
        },
        {
          id: 4,
          title: 'My video 3',
          subtitle: '11. 1. 2020',
          image: 'https://picsum.photos/id/1016/600/500',
        },
        {
          id: 5,
          title: 'My video 5',
          subtitle: '19. 2. 2020',
          image: 'https://picsum.photos/id/1023/600/500',
        },
        {
          id: 6,
          title: 'My video 6',
          subtitle: '18. 1. 2020',
          image: 'https://picsum.photos/id/1029/600/500',
        },
        {
          id: 7,
          title: 'My video 7',
          subtitle: '11. 1. 2020',
          image: 'https://picsum.photos/id/1036/600/500',
        },
      ])
    }, 1000)
  })
}

export interface UserList {
  id: number
  title: string
  subtitle: string
  subtitles: [string]
  image: string | null
}

export function getPlayers(filter: Record<string, unknown> = {}) {
  console.log('Players API call', filter)

  return new Promise(res => {
    setTimeout(() => {
      res([
        {
          id: 1,
          title: 'Henry Harolds',
          image: null,
          subtitles: ['HK Košice, SK', 'Defender'],
        },
        {
          id: 2,
          title: 'Bob Chef',
          image: null,
          subtitles: ['HK Košice, SK', 'Defender'],
        },
        {
          id: 3,
          title: 'Louis Futoni',
          image: null,
          subtitles: ['HK Košice, SK', 'Defender'],
        },
      ])
    }, 1000)
  })
}

export interface ProgramList {
  id: number
  title: string
  text: string
  image: string | null
}

export function getPrograms(filter: Record<string, unknown> = {}) {
  console.log('Programs API call', filter)

  return new Promise(res => {
    setTimeout(() => {
      res([
        {
          id: 1,
          title: 'THM',
          image: 'https://picsum.photos/id/559/600/300',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          id: 2,
          title: 'Surfing',
          image: 'https://picsum.photos/id/643/600/300',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
      ])
    }, 1000)
  })
}

export interface Club {
  id: number
  title: string
  image: string | null
  subtitle: string | null
}

export function getClubs(filter: Record<string, unknown> = {}) {
  console.log('Clubs API call', filter)

  return new Promise(res => {
    setTimeout(() => {
      res([
        {
          id: 1,
          title: 'VUT Brno',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Brno, CZ',
        },
        {
          id: 2,
          title: 'Škola Dunkla Trenčín',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Trenčín, SK',
        },
        {
          id: 3,
          title: 'ZŠ Úvoz',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Brno, CZ',
        },
        {
          id: 4,
          title: 'ZŠ Slovan',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Brno, CZ',
        },
        {
          id: 5,
          title: 'VUT Brno',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Brno, CZ',
        },
        {
          id: 6,
          title: 'Škola Dunkla Trnčín',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Trenčín, SK',
        },
        {
          id: 7,
          title: 'ZŠ Úvoz',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Brno, CZ',
        },
        {
          id: 8,
          title: 'ZŠ Slovan',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Brno, CZ',
        },
        {
          id: 9,
          title: 'VUT Brno',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Brno, CZ',
        },
        {
          id: 10,
          title: 'Škola Dunkla Trnčín',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Trenčín, SK',
        },
        {
          id: 11,
          title: 'ZŠ Úvoz',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Brno, CZ',
        },
        {
          id: 12,
          title: 'ZŠ Slovan',
          image: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          subtitle: 'Brno, CZ',
        },
      ])
    }, 1000)
  })
}

export interface ChallengeList {
  id: number
  title: string
  text: string
}

export function getChallenges(filter: { type: string }) {
  console.log('Challenges API call', filter)

  return new Promise(res => {
    setTimeout(() => {
      res(
        filter.type === 'created'
          ? []
          : [
            {
              id: 1,
              title: filter.type === 'new' ? 'Do 50 pushups' : 'Learn spinorama',
              text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
            },
            {
              id: 2,
              title: filter.type === 'new' ? 'Run 100m really fast' : 'Go OUT',
              text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
            },
          ],
      )
    }, 1000)
  })
}

export interface TipsList {
  id: number
  title: string
  text: string
}

export function getTips(filter: { type: string }) {
  console.log('Tips API call', filter)

  return new Promise(res => {
    setTimeout(() => {
      res([
        {
          id: 1,
          title: '#' + 1 + ' How to',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          id: 2,
          title: '#' + 2 + ' Try this',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
        {
          id: 3,
          title: '#' + 3 + ' Stop doing that',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
        },
      ])
    }, 1000)
  })
}
