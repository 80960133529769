
import { IonTabBar, IonTabButton, IonTabs, IonIcon, IonPage } from '@ionic/vue'

import {
  addCircle,
  home,
  peopleSharp,
  checkbox,
  trophy,
  star,
  readerOutline,
  playCircleSharp,
  apertureOutline,
  closeCircle,
} from 'ionicons/icons'

import { ref } from 'vue'

export default {
  name: 'Tabs',
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
  },
  setup() {
    const tabs = {
      home: 'home',
      friends: 'friends',
      add: 'add',
      challenges: 'challenges',
      stats: 'stats',
    }
    const tab = ref('home')
    const onTabChange = (e: any) => {
      tab.value = e.tab
    }
    const buttons = [
      {
        text: 'Add Photo',
        icon: apertureOutline,
      },
      {
        text: 'Add Video',
        icon: playCircleSharp,
      },
      {
        text: 'Add Task',
        icon: readerOutline,
      },
    ]
    return {
      tab,
      tabs,
      buttons,
      onTabChange,
      addCircle,
      closeCircle,
      home,
      peopleSharp,
      checkbox,
      trophy,
      star,
      readerOutline,
      playCircleSharp,
      apertureOutline,
    }
  },
  data(): any {
    return {
      addOptionsVisible: false,
    }
  },
}
