
import { IonGrid, IonRow, IonCol, IonBadge, IonIcon } from '@ionic/vue'

import {
  peopleSharp,
  trophySharp,
  rocketSharp,
  readerSharp,
  playCircleSharp,
  bulbSharp,
} from 'ionicons/icons'

export default {
  name: 'Notifications',
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonBadge,
    IonIcon,
  },
  setup() {
    return {
      peopleSharp,
      trophySharp,
      rocketSharp,
      readerSharp,
      playCircleSharp,
      bulbSharp,
    }
  },
  data() {
    return {
      notifications: [
        ['Friends', peopleSharp, 12, '/tabs/friends'],
        ['Challenges', trophySharp, 2, '/tabs/challenges'],
        ['Tasks', readerSharp, 6, '/tabs/home/todos'],
        ['Programs', rocketSharp, 1, '/tabs/programs'],
        ['Training videos', playCircleSharp, 3, '/tabs/training-videos'],
        ['Hockey tips', bulbSharp, 56, '/tabs/tips'],
      ],
    }
  },
}
