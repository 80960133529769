<template>
  <ion-row class="ion-align-items-center ion-justify-content-between heading">
    <ion-col size="auto" class="heading-col">
      <ion-icon
        v-if="iconName"
        :icon="iconName"
        size="large"
      />
      <h2>{{ headingText }}</h2>
    </ion-col>
    <ion-col v-if="linkText" size="auto">
      <a href="" class="black-link"> {{ linkText }}</a>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
import { IonCol, IonRow, IonIcon } from '@ionic/vue'

export default {
  components: {
    IonCol,
    IonRow,
    IonIcon,
  },
  props: {
    headingText: String,
    linkText: String,
    iconName: String,
  },
}
</script>

<style scoped>
.heading {
  margin-top: 25px;
}

.heading-col {
  align-items: center;
  display: flex;
  flex-direction: row;
}

h2 {
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 3px 0 0 0;
}

.black-link {
  display: block;
  font-family: var(--font-family-oswald);
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: underline;
}

ion-col {
  padding: 0;
}

ion-row {
  width: 100%;
  margin-bottom: 15px;
}

ion-icon {
  padding-right: 10px;
}
</style>
