<template>
  <img
    src="@/assets/img/test-img.jpg"
    alt="hockey"
    class="feed-img"
  />
</template>

<script lang="ts">
export default {
  props: {
    postImgSrc: String,
  },
}
</script>

<style scoped>
.feed-img {
  border-radius: 10px;
  max-height: 130px;
  object-fit: cover;
  width: 100%;
}
</style>
