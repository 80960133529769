<template>
  <div class="grey-container">
    <div class="shadow-line" />
    <div id="home-grey-box-content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TabsBox',
}
</script>

<style scoped>
.grey-container {
  padding-top: 15px;
  margin: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 100%;
}

.shadow-line {
  position: relative;
  top: 6px;
  background-color: var(--tabs-color);
  height: 6px;
  width: 73px;
  border-radius: 20px;
  margin: 0 auto 15px auto;
}
</style>
