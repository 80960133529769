import { createRouter, createWebHashHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

import { store } from '@/store/index'

import Tabs from '@/views/navigation/Tabs.vue'
import Home from '@/views/homepage/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/Login.vue'),
  },
  {
    name: 'Options',
    path: '/options',
    component: () => import('@/views/navigation/MainMenu.vue'),
  },
  {
    name: 'Settings',
    path: '/settings',
    component: () => import('@/views/navigation/Settings.vue'),
  },
  {
    name: 'Tabs',
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        name: 'TabsHome',
        path: 'home/',
        component: Home,
        children: [
          {
            name: 'TabsHomeDailyFeed',
            path: 'daily-feed',
            component: () => import('@/views/homepage/DailyFeedPage.vue'),
          },
          {
            name: 'TabsHomeTodos',
            path: 'todos',
            component: () => import('@/views/homepage/TodosPage.vue'),
          },
          {
            name: 'TabsHomeNotifications',
            path: 'notifications',
            component: () => import('@/views/homepage/NotificationsPage.vue'),
          },
        ],
      },
      {
        name: 'TabsFriends',
        path: 'friends',
        component: () => import('@/views/FriendsList.vue'),
      },
      {
        name: 'TabsChallenges',
        path: 'challenges',
        component: () => import('@/views/challenges/ChallengesList.vue'),
      },
      {
        name: 'Programs',
        path: 'programs',
        component: () => import('@/views/programs/ProgramsList.vue'),
      },
      {
        name: 'TrainingVideos',
        path: 'training-videos',
        component: () => import('@/views/videos/VideosList.vue'),
      },
      {
        name: 'Tips',
        path: 'tips',
        component: () => import('@/views/HockeyTips.vue'),
      },
      {
        name: 'MyPosts',
        path: 'my-posts',
        component: () => import('@/views/PostsList.vue'),
      },
    ],
  },
  {
    name: 'Profile',
    path: '/profile',
    component: () => import('@/views/profile/Profile.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  await (store as any).restored
  const isAuthenticated = store.state.authToken && store.state.user
  if (!isAuthenticated && to.name !== 'Login') {
    next({ name: 'Login' })
  } else if (to.path === '/') {
    next({ name: 'TabsHome' })
  } else {
    next()
  }
})

export default router
