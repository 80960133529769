import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store, createLogger } from 'vuex'
import VuexPersistence from 'vuex-persist'

import * as storage from '@/services/storage'

import actions from './actions'
import mutations from './mutations'

import { User } from '@/api/api'

export interface Notification {
  title: string
}

export interface State {
  authToken: string | null
  user: User | null
  notifications: Notification[]
}

const vuexLocal = new VuexPersistence<State>({
  storage,
  asyncStorage: true,
})

export const STORAGE_STATE_KEY = 'persisted_state'

export const key: InjectionKey<Store<State>> = Symbol()

const debug: boolean = process.env.NODE_ENV !== 'production'

const defaultState: State = {
  authToken: null,
  user: null,
  notifications: [],
}

export const store = createStore<State>({
  state: defaultState,
  mutations,
  actions,
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],
})

export function useStore() {
  return baseUseStore(key)
}
