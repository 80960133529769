
import BackLink from '@/components/settings/BackLink.vue'
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonAvatar,
  IonButton,
  IonBadge,
  IonIcon,
} from '@ionic/vue'
import SpinoramaLogo from '@/components/header/SpinoramaLogo.vue'
import { useStore } from '@/store'

export default {
  name: 'AppHeader',
  components: {
    IonButtons,
    IonButton,
    IonHeader,
    IonToolbar,
    IonAvatar,
    IonBadge,
    IonIcon,
    SpinoramaLogo,
    BackLink,
  },
  props: {
    title: String,
    backLinkHeader: Boolean, // set to 'true' for header with backlink and page title, header with logo is default
  },
  setup() {
    const { state } = useStore()

    return {
      user: state.user,
    }
  },
}
