
import NotificationsBox from '@/components/homepage/NotificationsBox.vue'

import { IonGrid, IonRow, IonCol } from '@ionic/vue'

import { useStore } from '@/store'

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    NotificationsBox,
  },
  props: {
    showNews: Boolean,
  },
  setup() {
    const { state } = useStore()

    return {
      user: state.user,
    }
  },
}
