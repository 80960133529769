<template>
  <ion-toolbar :class="{ white: white }">
    <ion-buttons slot="start">
      <ion-back-button
        default-href="/tabs/home"
        :text="text"
        :icon="arrowBack"
      />
    </ion-buttons>
  </ion-toolbar>
</template>

<script lang="ts">
import { IonBackButton, IonToolbar, IonButtons } from '@ionic/vue'

export default {
  name: 'Profile',
  components: {
    IonButtons,
    IonBackButton,
    IonToolbar,
  },
  props: {
    text: String,
    white: Boolean,
  },
}
</script>

<style scoped>
ion-toolbar {
  --background: transparent;
  --color: var(--ion-color-light-contrast);
}

ion-toolbar.white {
  --color: var(--ion-color-light);
}

ion-back-button {
  font-weight: 300;
}
ion-back-button::part(icon) {
  font-size: 30px;
}
</style>
