import { Storage } from '@capacitor/storage'

export interface AsyncStorage {
  getItem(
    key: string
  ): Promise<{
    value: string | null
  }>
  setItem<T>(key: string, value: string): Promise<T>
  removeItem(key: string): Promise<void>
  clear(): Promise<void>
  length(): Promise<number>
  key(keyIndex: number): Promise<string>
}

export async function setItem<T>(key: string, value: any): Promise<T> {
  await Storage.set({
    key: key,
    value: JSON.stringify(value),
  })
  return value
}

export async function getItem(key: string): Promise<any> {
  const item = await Storage.get({ key: key })
  return item.value ? JSON.parse(item.value) : null
}

export async function removeItem(key: string): Promise<void> {
  await Storage.remove({
    key: key,
  })
}

export async function clear() {
  await Storage.clear()
}

export async function length() {
  const s = await Storage.keys()
  return s.keys.length
}

export async function key(keyIndex: number) {
  const s = await Storage.keys()
  return s.keys[keyIndex]
}

export {}
