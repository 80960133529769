
import { IonAvatar, IonBadge, IonIcon } from '@ionic/vue'

import { peopleSharp } from 'ionicons/icons'

export default {
  components: {
    IonAvatar,
    IonBadge,
    IonIcon,
  },
  props: {
    authorAvatarSrc: String,
    friends: Boolean,
  },
  setup() {
    return {
      peopleSharp,
    }
  },
}
