<template>
  <ion-button>{{ $t('Share') }}</ion-button>
</template>

<style scoped>
ion-button {
  --background: var(--ion-color-light-shade);
  color: var(--ion-color-primary);
  font-weight: 300;
  --border-radius: 186px;
  --box-shadow: none;
  margin-left: auto;
}
</style>
