<template>
  <ion-row id="three-tabs-nav">
    <ion-col>
      <router-link to="/tabs/home/daily-feed">
        <span
          id="feed"
          class="three-tabs-item"
          :class="{ linkActive: feedActive }"
        >{{
          $t('Daily Feed')
        }}</span>
      </router-link>
    </ion-col>
    <ion-col>
      <router-link to="/tabs/home/todos">
        <span
          id="todos"
          class="three-tabs-item"
          :class="{ linkActive: todosActive }"
        >{{
          $t('Things to do')
        }}</span>
      </router-link>
    </ion-col>
    <ion-col>
      <router-link to="/tabs/home/notifications">
        <span
          id="alerts"
          class="three-tabs-item"
          :class="{ linkActive: alertsActive }"
        >{{
          $t('Notifications')
        }}</span>
      </router-link>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
import { IonRow, IonCol } from '@ionic/vue'

export default {
  components: {
    IonRow,
    IonCol,
  },
  props: {
    feedActive: Boolean,
    todosActive: Boolean,
    alertsActive: Boolean,
  },
}
</script>

<style scoped>
ion-col {
  padding-bottom: 0;
}

#three-tabs-nav {
  margin-top: 20px;
  padding: 0 20px;
}

.three-tabs-item {
  display: block;
  text-transform: uppercase;
  text-align: center;
  font-weight: 300;
  font-size: 13px;
  padding-bottom: 17px;
}

.linkActive {
  color: var(--ion-color-secondary);
  border-bottom: 4px solid var(--ion-color-secondary);
}
</style>
