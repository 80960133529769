<template>
  <h1 :class="size">
    <span class="text-color-secondary">Spino</span><span>rama</span>
  </h1>
</template>

<script lang="ts">
export default {
  name: 'SpinoramaLogo',
  props: {
    size: String,
  },
}
</script>

<style scoped>
h1 {
  font-family: var(--font-family-oswald);
  font-weight: lighter;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
}

.big {
  font-size: var(--logo-font-size-bigger);
}

.small {
  font-size: var(--logo-font-size-smaller);
}
</style>
