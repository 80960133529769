<template>
  <ion-grid>
    <ion-row>
      <cathegory-heading
        :icon-name="peopleSharp"
        heading-text="friend's posts"
        link-text="show more"
      />
    </ion-row>
    <div class="horizontal-scroll">
      <ion-row class="posts-row">
        <feed-post
          v-for="post of testPosts"
          :key="post.id"
          :post="post"
        />
      </ion-row>
    </div>
  </ion-grid>
</template>

<script lang="ts">
import FeedPost from '@/components/homepage/DailyFeedPost.vue'
import CathegoryHeading from '@/components/homepage/CathegoryHeading.vue'

import { IonGrid, IonRow } from '@ionic/vue'

import { peopleSharp } from 'ionicons/icons'

export default {
  components: {
    IonGrid,
    IonRow,
    CathegoryHeading,
    FeedPost,
  },
  setup() {
    return {
      peopleSharp,
    }
  },
  data() {
    return {
      testPosts: [
        {
          id: 1,
          authorName: 'Henry Harolds',
          authorAvatar:
            'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          postImg: '@/assets/img/test-img.jpg',
          postVideo: '',
          posted: 'Jun 10 2021 08:32:07 GMT+0200',
          social: {
            likes: 158,
            shares: 63,
            comments: 15,
          },
          friends: true,
        },
        {
          id: 2,
          authorName: 'Bob Chef',
          authorAvatar:
            'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          postImg: '@/assets/img/test-img.jpg',
          postVideo: '',
          posted: 'Jun 10 2021 08:38:09 GMT+0200',
          social: {
            likes: 15,
            shares: 6,
            comments: 5,
          },
          friends: true,
        },
        {
          id: 3,
          authorName: 'Louis Futtoni',
          authorAvatar:
            'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          postImg: '@/assets/img/test-img.jpg',
          postVideo: '',
          posted: 'Jun 10 2021 09:32:07 GMT+0200',
          social: {
            likes: 60,
            shares: 18,
            comments: 8,
          },
          friends: true,
        },
        {
          id: 4,
          authorName: 'Bob Chef',
          authorAvatar:
            'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y',
          postImg: '@/assets/img/test-img.jpg',
          postVideo: '',
          posted: 'Jun 10 2021 11:36:12 GMT+0200',
          social: {
            likes: 13,
            shares: 2,
            comments: 0,
          },
          friends: true,
        },
      ],
    }
  },
}
</script>

<style scoped>
.horizontal-scroll {
  overflow-x: scroll;
}

.posts-row {
  width: max-content;
}
</style>
