import { logIn } from '@/api/api'

const actions = {
  login({ commit }: { commit: any }, authData: { email: string, password: string }) {
    return new Promise((res, rej) => {
      logIn(authData.email, authData.password)
        .then(result => {
          res(result)
        })
        .catch(error => rej(error))
    })
  },
}

export default {
  ...actions,
}
