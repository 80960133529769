<template>
  <ion-page>
    <ion-tabs @ionTabsDidChange="onTabChange">
      <transition name="fade">
        <ion-backdrop v-if="addOptionsVisible" @ionBackdropTap="addOptionsVisible = false" />
      </transition>
      <transition name="slide">
        <div class="icon-tabs">
          <div v-if="addOptionsVisible" class="icons-row add-options">
            <div
              v-for="(button, index) in buttons"
              :key="index"
              class="orange-box"
            >
              <div>
                <ion-icon :icon="button.icon" />
                <p>{{ button.text }}</p>
              </div>
            </div>
          </div>

          <ion-tab-bar slot="bottom" class="icons-row">
            <ion-tab-button :tab="tabs.home" href="/tabs/home">
              <ion-icon :icon="tab === tabs.home ? home : home" />
            </ion-tab-button>

            <ion-tab-button :tab="tabs.friends" href="/tabs/friends">
              <ion-icon :icon="tab === tabs.friends ? peopleSharp : peopleSharp" />
            </ion-tab-button>

            <ion-tab-button :tab="tabs.add">
              <ion-icon
                v-if="!addOptionsVisible"
                :icon="addCircle"
                class="add-button"
                @click="addOptionsVisible = !addOptionsVisible"
              />
              <ion-icon
                v-else
                :icon="closeCircle"
                class="close-button"
                @click="addOptionsVisible = !addOptionsVisible"
              />
            </ion-tab-button>

            <ion-tab-button :tab="tabs.challenges" href="">
              <ion-icon :icon="tab === tabs.challenges ? trophy : trophy" />
            </ion-tab-button>

            <ion-tab-button :tab="tabs.stats" href="">
              <ion-icon :icon="tab === tabs.stats ? star : star" />
            </ion-tab-button>
          </ion-tab-bar>
        </div>
      </transition>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { IonTabBar, IonTabButton, IonTabs, IonIcon, IonPage } from '@ionic/vue'

import {
  addCircle,
  home,
  peopleSharp,
  checkbox,
  trophy,
  star,
  readerOutline,
  playCircleSharp,
  apertureOutline,
  closeCircle,
} from 'ionicons/icons'

import { ref } from 'vue'

export default {
  name: 'Tabs',
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
  },
  setup() {
    const tabs = {
      home: 'home',
      friends: 'friends',
      add: 'add',
      challenges: 'challenges',
      stats: 'stats',
    }
    const tab = ref('home')
    const onTabChange = (e: any) => {
      tab.value = e.tab
    }
    const buttons = [
      {
        text: 'Add Photo',
        icon: apertureOutline,
      },
      {
        text: 'Add Video',
        icon: playCircleSharp,
      },
      {
        text: 'Add Task',
        icon: readerOutline,
      },
    ]
    return {
      tab,
      tabs,
      buttons,
      onTabChange,
      addCircle,
      closeCircle,
      home,
      peopleSharp,
      checkbox,
      trophy,
      star,
      readerOutline,
      playCircleSharp,
      apertureOutline,
    }
  },
  data(): any {
    return {
      addOptionsVisible: false,
    }
  },
}
</script>

<style>
.icon-tabs {
  background-color: var(--tabs-color);
  box-shadow: 0px 0px 6px var(--ion-color-medium-shade);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: -30px;
  padding: 7px 5px 5px 5px;
  flex-direction: column;
  z-index: 999;
}

.icons-row {
  display: flex;
  align-items: center;
  width: 100%;
}

ion-tab-bar {
  z-index: 3;
  --background: transparent;
  border: none;
}

ion-tab-button {
  --color: var(--tab-icon-color);
  --color-selected: var(--tab-focus-color);
}

ion-icon {
  font-size: 28px;
}

.add-button,
.close-button {
  font-size: 62px;
}
.close-button:hover,
.add-button {
  color: var(--ion-color-secondary);
}
.add-button:hover,
.close-button {
  color: var(--ion-color-primary);
}

.orange-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-light);
  font-size: 10px;
  padding: 5px;
  margin: 5px auto;
  text-align: center;
  background-color: var(--ion-color-secondary);
  width: 100px;
  height: 100px;
  border-radius: 25%;
}
.orange-box:hover {
  background-color: var(--ion-color-primary);
}
.orange-box ion-icon {
  font-size: 60px;
}
.orange-box p {
  margin: 0;
}

.add-options {
  border-bottom: 3px solid var(--ion-color-light);
  padding-bottom: 12px;
  margin-bottom: 6px;
}

ion-backdrop {
  opacity: 0.4;
}

.slide-enter-active,
.fade-enter-active {
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}

.slide-leave-active {
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}
</style>
