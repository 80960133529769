
import { IonRow, IonCol } from '@ionic/vue'

export default {
  components: {
    IonRow,
    IonCol,
  },
  props: {
    feedActive: Boolean,
    todosActive: Boolean,
    alertsActive: Boolean,
  },
}
