<template>
  <ion-row>
    <ion-col>
      <ion-icon :icon="thumbsUpOutline" />
      <span class="social-num">{{ social.likes }}</span>
    </ion-col>
    <ion-col>
      <ion-icon :icon="shareSocialOutline" />
      <span class="social-num">{{ social.shares }}</span>
    </ion-col>
    <ion-col>
      <ion-icon :icon="chatbubbleEllipsesOutline" />
      <span class="social-num">{{ social.comments }}</span>
    </ion-col>
  </ion-row>
</template>

<script lang="ts">
import { IonRow, IonCol, IonIcon } from '@ionic/vue'

import { thumbsUpOutline, shareSocialOutline, chatbubbleEllipsesOutline } from 'ionicons/icons'

export default {
  components: {
    IonRow,
    IonCol,
    IonIcon,
  },
  props: {
    social: Object,
  },
  setup() {
    return {
      thumbsUpOutline,
      shareSocialOutline,
      chatbubbleEllipsesOutline,
    }
  },
}
</script>

<style scoped>
.social-num {
  display: block;
  text-align: center;
  font-size: 11px;
}
</style>
