
import AuthorAvatar from '@/components/posts/AuthorAvatar.vue'
import PostInfo from '@/components/posts/PostInfo.vue'
import PostImg from '@/components/posts/PostImg.vue'
import SocialsInfo from '@/components/posts/SocialsInfo.vue'
import ShareButton from '@/components/posts/ShareButton.vue'

import { IonGrid, IonRow, IonCol } from '@ionic/vue'

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    AuthorAvatar,
    PostInfo,
    PostImg,
    SocialsInfo,
    ShareButton,
  },
  props: {
    post: Object,
  },
}
