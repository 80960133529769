<template>
  <div class="white-container">
    <ion-grid>
      <ion-row>
        <ion-col>
          <author-avatar :author-avatar-src="post.authorAvatar" :friends="post.friends" />
        </ion-col>
        <ion-col>
          <post-info :author="post.authorName" :posted="post.posted" />
        </ion-col>
      </ion-row>
      <ion-row>
        <post-img :post-img-src="post.postImg" />
      </ion-row>
      <ion-row class="social-row">
        <socials-info :social="post.social" />
        <share-button />
      </ion-row>
    </ion-grid>
  </div>
</template>

<script lang="ts">
import AuthorAvatar from '@/components/posts/AuthorAvatar.vue'
import PostInfo from '@/components/posts/PostInfo.vue'
import PostImg from '@/components/posts/PostImg.vue'
import SocialsInfo from '@/components/posts/SocialsInfo.vue'
import ShareButton from '@/components/posts/ShareButton.vue'

import { IonGrid, IonRow, IonCol } from '@ionic/vue'

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    AuthorAvatar,
    PostInfo,
    PostImg,
    SocialsInfo,
    ShareButton,
  },
  props: {
    post: Object,
  },
}
</script>

<style scoped>
.white-container {
  margin-left: 0;
  max-width: 296px;
  padding: 12px;
}

.white-container:hover {
  border: 2px solid var(--ion-color-medium);
}

.social-row {
  margin-top: 10px;
  color: var(--ion-color-primary);
}
</style>
